* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
}

canvas {
  left: 0;
  top: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
}
